@charset "utf-8";

$primary-color: #0b4345 !default;
@import "minimal-mistakes/skins/contrast"; // skin
@import "minimal-mistakes"; // main partials

.author__content a.about__btn {
    color: white;
    background-color: $primary-color;
    padding: 10px;
}

.archive__item a {
    color: $primary-color;
}
